@import 'config.less';

sToggle,
sRadio,
sCheckbox {
    > div {
        min-width: 24px;
    }

    label {
        cursor: pointer;
    }
}

sCheckbox,
sRadio,
sToggle,
sInput,
sTextarea,
sSelect {
    display: inline-block;
    width: 100%;
}

sButton {
    display: inline-block;
}
