@import 'config';

.tabs {
    position: relative;
    display: flex;
    gap: 14px;
    background-color: var(--white);
    padding-top: 12px;
    padding-left: 40px;

    @media (max-width: @lg) {
        gap: 12px;
        padding-left: 20px;
    }

    @media (max-width: @md) {
        gap: 0;
        justify-content: stretch;
        padding-top: 8px;
        padding-left: 0;
    }

    > div {
        padding: 24px;

        i {
            margin-right: 10px;
        }

        @media (max-width: @lg) {
            padding: 20px;
        }

        @media (max-width: @md) {
            width: 100%;
            text-align: center;
            padding: 14px;

            i {
                margin-right: 6px;
            }
        }

        @media (max-width: @sm) {
            padding: 14px 12px;
        }

        @media (max-width: @xs) {
            padding: 14px 10px;

            i {
                margin-right: 4px;
            }
        }
    }

    .active {
        background-color: var(--body-background);
        font-weight: 500;
    }
}
