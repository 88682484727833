/* tooltip */
[tooltip] {
    position: relative;
    cursor: pointer;
    border-bottom: dotted 1px;
}

[tooltip]:after {
    opacity: 0;
    pointer-events: none;
    transition: all 0.18s ease-out 0.18s;
    font-weight: 400 !important;
    font-style: normal !important;
    text-shadow: none !important;
    font-size: 12px !important;
    background: rgba(17, 17, 17, 0.9);
    border-radius: 4px;
    color: #fff;
    content: attr(tooltip);
    padding: 0.5em 1em;
    position: absolute;
    white-space: nowrap;
    z-index: 10;
}

[tooltip]:before {
    background: no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.18s ease-out 0.18s;
    content: '';
    position: absolute;
    z-index: 10;
}
[tooltip]:hover:before,
[tooltip]:hover:after {
    opacity: 1;
    pointer-events: auto;
}

[tooltip]:after {
    left: 50%;
    margin-top: 11px;
    top: 100%;
    transform: translate(-50%, -10px);
}

[tooltip]:before {
    background: no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    left: 50%;
    margin-top: 5px;
    top: 100%;
    transform: translate(-50%, -10px);
}

[tooltip]:hover:after {
    transform: translate(-50%, 0);
}

[tooltip]:hover:before {
    transform: translate(-50%, 0);
}

[tooltip]:after {
    white-space: normal;
    width: 200px;
}
