@import './config.less';

.redactor-clips-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.redactor-clips-list li {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px;
}
.redactor-clips-list span {
    white-space: nowrap;
    background: var(--primary);
    color: #fff;
    display: inline-block;
    padding: 6px 8px;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
}

.redactor-styles {
    color: @redactorFg!important;
}

.redactor-styles a[rel*='button'] {
    border-radius: 2px;
    background: transparent;
    color: var(--primary) !important;
    text-decoration: none !important;
    display: inline-block;
    padding: 5px 10px;
    border: solid 1px var(--primary);
}

.redactor-dropdown-format .redactor-dropdown-item-h1 {
    font-size: 32px !important;
}

.redactor-dropdown-format .redactor-dropdown-item-h2 {
    font-size: 28px !important;
}

.redactor-dropdown-format .redactor-dropdown-item-h3 {
    font-size: @fontSizeHeader !important;
}

.redactor-dropdown-format .redactor-dropdown-item-h4 {
    font-size: @fontSizeLarge !important;
}

.redactor-box.redactor-styles-on {
    background: @redactorBackground !important;
}
